import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nav-collapse"
export default class extends Controller {
  connect() {
    if (this.element.querySelector('.active')) {
      this.element.classList.add('show')
    }
  }
}
