import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'toggle']

  getValue(element, selector) {
    return (
      element.matches ||
      element.matchesSelector ||
      element.msMatchesSelector ||
      element.mozMatchesSelector ||
      element.webkitMatchesSelector ||
      element.oMatchesSelector
    ).call(element, selector)
  }

  toggleElements(elements, value) {
    var toggleTarget = this.toggleTarget

    elements.forEach(function(el, i) {
      if(el != toggleTarget) {
        el.disabled = value
      }
    })
  }

  disableInputs() {
    var inputs = this.containerTarget.querySelectorAll('input, select, checkbox')
    var toggle = this.getValue(this.toggleTarget, ':checked')

    this.toggleElements(inputs, toggle)
  }

  connect() {
    this.toggleTarget.addEventListener('change', this.disableInputs.bind(this))
    this.disableInputs()
  }
}
