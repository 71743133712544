import { Controller } from '@hotwired/stimulus'
import { get }        from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'loading', 'registration', 'error' ]

  connect() {
    this.attempts = 0
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetchStatus()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  renderStatusResponse(data) {
    if (data['registered'] === true) {
      this.loadingTarget.setAttribute('style', 'display: none;')
      this.errorTarget.setAttribute('style', 'display: none;')
      this.registrationTarget.setAttribute('style', 'display: block;')
    } else {
      this.attempts++
      if (this.attempts > 5) {
        this.errorTarget.setAttribute('style', 'display: block;')
      }
    }
  }

  async fetchStatus() {
    const response = await get(location.href, {
      responseKind: 'json'
    })

    if (response.ok) {
      const data = JSON.parse(await response.text)
      this.renderStatusResponse(data)
    }
  }
}
