import { Controller } from '@hotwired/stimulus'
import { get }        from '@rails/request.js'

export default class extends Controller {
  static targets = ['cta', 'disabled']
  static values = { url: String }

  initialize() {
    this.fetchStatus();
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    if (!this.refreshTimer) {
      this.refreshTimer = setInterval(() => {
        this.fetchStatus()
      }, 1000)
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  async fetchStatus() {
    const response = await get(this.urlValue, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = JSON.parse(await response.text)
      this.refreshStatus(data)
    }
  }

  refreshStatus(data) {
    if (data.pending_changes) {
      this.startRefreshing()
      this.ctaTarget.classList.add('d-none');
      this.disabledTarget.classList.remove('d-none');
    } else {
      this.stopRefreshing();
      this.ctaTarget.classList.remove('d-none');
      this.disabledTarget.classList.add('d-none');
    }
  }
}
