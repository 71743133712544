import { Controller } from '@hotwired/stimulus'

require('chart.js')

export default class extends Controller {
  connect() {
    this.element.classList.add('chartjs-render-monitor');

    new Chart(
      this.element,
      {
        type: 'line',
        data: {
          labels: JSON.parse(this.element.dataset.labels),
          datasets: JSON.parse(this.element.dataset.events)
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: 'rgb(255,255,255)',
            bodyFontColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            caretPadding: 10,
          },
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
        },
      }
    );
  }
}
