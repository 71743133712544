// Config Information
// https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html 

import { Controller } from '@hotwired/stimulus'
import ClassicEditor from '@citysupport/ckeditor5-build-classic'

export default class extends Controller {
  connect() {
    ClassicEditor.create(this.element, {
      plugins: [
        'Essentials',
        'Autoformat',
        'Bold',
        'Italic',
        'Underline',
        'Heading',
        'Link',
        'List',
        'Paragraph',
        'PasteFromOffice',
        'Table',
        'TableToolbar',
      ],
      toolbar: {
        items: [
          'heading', '|',
          'bold', 'italic', 'underline', 'link', 'bulletedList', 'numberedList', '|',
          'insertTable', 'tableRow', 'tableColumn', 'mergeTableCells', '|',
          'undo', 'redo'
        ]
      },
      title: {
        placeholder: false
      },
      link: {
        decorators: [
          {
            mode: 'manual',
            label: 'Open in a new tab',
            defaultValue: true,
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        ]
      }
    });
  }
}
