import { Controller } from '@hotwired/stimulus'
import { Litepicker } from 'litepicker'

export default class extends Controller {
  static targets = ['input']

  connect() {
    const picker = new Litepicker({
      element: this.inputTarget
    });
  }
}
