import { Controller } from '@hotwired/stimulus'

const directionMappings = {
  'EAST': 'E',
  'WEST': 'W',
  'NORTH': 'N',
  'NORTHEAST': 'NE',
  'NORTHWEST': 'NE',
  'SOUTH': 'S',
  'SOUTHEAST': 'SE',
  'SOUTHWEST': 'SW'
}

export default class extends Controller {
  static targets = [
    'poBoxAddress',
    'form', 'streetAddress', 'streetNumber', 'streetDirectionPrefix', 'streetName', 'streetType', 'streetDirectionSuffix', 'streetUnitNumber', 'city', 'state', 'zip',
    'geoLookup', 'geoLookupSubmitText', 'geoLookupSubmitLoad', 'geoLookupAddress',
    'geoLookupResultsTitle', 'geoLookupResultsItems', 'geoLookupResultsEmpty', 'geoLookupResultsError'
  ]

  static values = {
    geoLookupUrl: String,
    geoLookupCity: String,
    geoLookupState: String
  }

  initialize() {
    this.geoLookupResults = []
  }

  connect() {
    if (this.hasGeoLookupTarget) {
      this.formTarget.classList.add('d-none')
    }
  }

  po_box() {
    this.streetAddressTarget.classList.toggle('d-none')
    this.poBoxAddressTarget.classList.toggle('d-none')
  }

  geoLookupSubmit(event) {
    event.preventDefault()

    this.geoLookupSubmitTextTarget.classList.add('d-none')
    this.geoLookupSubmitLoadTarget.classList.remove('d-none')

    this.geoLookup()
  }

  geoLookupReset(event) {
    this.geoLookupAddressTarget.value = ''
    this.geoLookupAddressTarget.focus()

    this.geoLookupResultsItemsTarget.innerHTML = ''

    this.geoLookupResultsTitleTarget.classList.add('d-none')
    this.geoLookupResultsItemsTarget.classList.add('d-none')
    this.geoLookupResultsEmptyTarget.classList.add('d-none')
    this.geoLookupResultsErrorTarget.classList.add('d-none')
  }

  async geoLookup() {
    const url = new URL(this.geoLookupUrlValue)
    url.search = this.geoLookupUrlParams()

    const request = new Request(url)
    const response = await fetch(request)

    if (response) {
      this.geoLookupSubmitLoadTarget.classList.add('d-none')
      this.geoLookupSubmitTextTarget.classList.remove('d-none')
    }

    if (response.ok) {
      const data = await response.json()
      this.geoLookupResultsHandler(data.candidates)
      this.geoLookupResultsErrorTarget.classList.add('d-none')
    } else {
      this.geoLookupResultsErrorTarget.classList.remove('d-none')
    }
  }

  async geoLookupResultsHandler(results) {
    this.geoLookupResults = results
    this.geoLookupResultsItemsTarget.innerHTML = ''

    if (results.length == 0) {
      this.geoLookupResultsItemsTarget.innerHTML = ''
      this.geoLookupResultsItemsTarget.classList.add('d-none')
      this.geoLookupResultsTitleTarget.classList.add('d-none')
      this.geoLookupResultsEmptyTarget.classList.remove('d-none')
    } else {
      results.forEach( (result, idx) => {
        let span1 = document.createElement('span')
        span1.classList.add('d-block')
        span1.innerHTML = result.attributes['StAddr']
        if (result.attributes['SubAddUnit'].length > 0) {
          span1.innerHTML += ', ' + result.attributes['SubAddUnit']
        }

        let span2 = document.createElement('span')
        span2.classList.add('d-block')
        span2.innerHTML = result.attributes['City'] + ', '
        span2.innerHTML += result.attributes['StateAbbr'] + ' ' + result.attributes['ZIP']

        let button = document.createElement('button')
        button.appendChild(span1)
        button.appendChild(span2)
        button.classList.add('btn')
        button.classList.add('btn-outline-secondary')
        button.classList.add('text-start')
        button.setAttribute('data-action', 'click->address-fields#geoLookupSelect')
        button.setAttribute('data-address-fields-geo-lookup-result-param', idx)

        let item = document.createElement('div')
        item.appendChild(button)
        item.classList.add('d-grid')

        this.geoLookupResultsItemsTarget.appendChild(item)
      })

      this.geoLookupResultsEmptyTarget.classList.add('d-none')
      this.geoLookupResultsTitleTarget.classList.remove('d-none')
      this.geoLookupResultsItemsTarget.classList.remove('d-none')
    }
  }

  geoLookupUrlParams() {
    let params = new URLSearchParams()

    params.append('street', this.geoLookupAddressTarget.value)
    params.append('city', this.geoLookupCityValue)
    params.append('state', this.geoLookupStateValue)
    params.append('matchOutOfRange', true)
    params.append('sourceCountry', 'United States')
    params.append('outFields', '*')
    params.append('f', 'json')

    return params
  }

  geoLookupSelect(event) {
    event.preventDefault()

    let result = this.geoLookupResults[event.params.geoLookupResult]

    this.streetNumberTarget.value           = result.attributes['AddNum']
    this.streetDirectionPrefixTarget.value  = this.directionMapping(result.attributes['StPreDir'])
    this.streetNameTarget.value             = result.attributes['StName']
    this.streetTypeTarget.value             = result.attributes['StType']
    this.streetDirectionSuffixTarget.value  = this.directionMapping(result.attributes['StDir'])
    this.streetUnitNumberTarget.value       = result.attributes['SubAddUnit']
    this.cityTarget.value                   = result.attributes['City']
    this.stateTarget.value                  = result.attributes['StateAbbr']
    this.zipTarget.value                    = result.attributes['ZIP']

    this.geoLookupTarget.classList.add('d-none')
    this.geoLookupResultsTitleTarget.classList.add('d-none')
    this.geoLookupResultsItemsTarget.classList.add('d-none')
    this.geoLookupResultsEmptyTarget.classList.add('d-none')
    this.formTarget.classList.remove('d-none')
  }

  geoLookupSelectUndo(event) {
    event.preventDefault()

    this.streetNumberTarget.value           = ''
    this.streetDirectionPrefixTarget.value  = ''
    this.streetNameTarget.value             = ''
    this.streetTypeTarget.value             = ''
    this.streetDirectionSuffixTarget.value  = ''
    this.streetUnitNumberTarget.value       = ''
    this.cityTarget.value                   = ''
    this.stateTarget.value                  = ''
    this.zipTarget.value                    = ''

    this.geoLookupTarget.classList.remove('d-none')
    this.geoLookupResultsTitleTarget.classList.remove('d-none')
    this.geoLookupResultsItemsTarget.classList.remove('d-none')
    this.formTarget.classList.add('d-none')
  }

  directionMapping(value) {
    let formatted = value.replace(/\s+/g, '').toUpperCase()
    let mapped = directionMappings[formatted]

    if (mapped) {
      return mapped
    } else {
      return value
    }
  }
}
