import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'status', 'update' ]

  result(event) {
    let [data, status, xhr] = event.detail;

    this.statusTarget.innerHTML = data.status;
    this.updateTarget.outerHTML = data.content;

    this.statusTarget.classList.add('text-success');
    this.statusTarget.classList.remove('text-danger');
    // $('[data-toggle="tooltip"]').tooltip()
  }

  error(event) {
    this.statusTarget.classList.add('text-danger');
  }
}
