import { Controller } from '@hotwired/stimulus'
import { Sortable }   from 'sortablejs'
import { post }        from '@rails/request.js'

export default class extends Controller {
  connect() {
    const url = this.element.dataset.sortableUrl;
    const token = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

    new Sortable(this.element, {
      sort: true,
      handle: '.handle',
      onEnd: async function (event) {
        const body = {
          faq: { 
            id: event.item.dataset.itemId,
            position: event.newIndex + 1
          }
        }

        const response = await post(url, {
          body: JSON.stringify(body)
        })
      }
    })
  }
}
