import { Controller }   from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'
import TomSelect        from 'tom-select'

export default class extends Controller {
  static targets = ['input']
  static values  = {
    disabled: Boolean
  }

  connect() {
    this.getRemoteOptions()
  }

  async getRemoteOptions() {
    const request = new FetchRequest('get', this.inputTarget.dataset.url)
    const response = await request.perform()

    if (response.ok) {
      const options = await response.json

      if (this.disabledValue) {
        options.results.forEach( (option, idx) => {
          let item = document.createElement('option')
          item.value = option.id
          item.innerHTML = option.text

          this.inputTarget.appendChild(item)
        })
      } else {
        this.newTomSelect(options)
      }
    }
  }

  newTomSelect(options) {
    new TomSelect(this.inputTarget, {
      valueField: 'id',
      labelField: 'text',
      searchField: ['text'],
      maxOptions: null,
      options: options
    })
  }
}
