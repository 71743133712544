import { Controller } from '@hotwired/stimulus'
import { Litepicker } from 'litepicker'

export default class extends Controller {
  static targets = ['start', 'end']

  connect() {
    const picker = new Litepicker({
      element: this.startTarget,
      elementEnd: this.endTarget,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      setup: (picker) => {
        picker.on('show', () => {
          let date = picker.getDate();
          if (date) {
            date.setMonth(date.getMonth() - 1);
            picker.gotoDate(date);
          }
        })
      }
    });
  }
}
