import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'authorization', 'charge', 'publicToken', 'accountId' ]

  authorize() {
    const el = this;
    const env = this.data.get('env') || 'production';
    const key = this.data.get('key');

    const handler = Plaid.create({
      clientName: 'CitySupport',
      countryCodes: ['US'],
      env: env,
      key: key,
      product: ['auth'],
      language: 'en',
      onLoad: function() {
      },
      onSuccess: function(public_token, metadata) {
        el.authorizationTarget.classList.add('d-none');
        el.chargeTarget.classList.remove('d-none');
        el.publicTokenTarget.value = public_token;
        el.accountIdTarget.value = metadata.account_id;
      },
      onExit: function(err, metadata) {
      },
      onEvent: function(eventName, metadata) {
      },
    });

    handler.open();
  }
}
