import { Controller } from '@hotwired/stimulus'
import { get }        from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'attempts', 'error', 'publicToken', 'accountId' ]

  connect() {
    this.attempts = 0
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetchStatus()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  async fetchStatus() {
    const url = this.data.get('status') || location.href
    const response = await get(url, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = JSON.parse(await response.text)

      if (data['synced_at'] != null) {
        window.location = this.data.get('url')
      } else {
        this.attempts++
        if (this.attempts > 8) {
          this.errorTarget.setAttribute('style', 'display: block;')
        }
      }
    }
  }
}
